import React, { Component } from 'react';
import Tone from 'tone';
// import { AvVolumeUp } from 'material-ui/svg-icons';
// import TextField from 'material-ui/TextField';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';

class Tonetool extends Component {

  constructor(props) {
    // let newOsci = new Tone.Oscillator(440, "sine").toMaster();
    super(props);
  
    this.state = {
      frequency: 440,
      phase: 0,
      volume: -60,
      started: false
      // pan: "left"
    };

    // let oscillator; 
    
    this.handleFrequencyChange = this.handleFrequencyChange.bind(this);
    this.stopOscillator = this.stopOscillator.bind(this);
    this.newOscillator = this.newOscillator.bind(this);
    this.volumeUp = this.volumeUp.bind(this);
    this.volumeDown = this.volumeDown.bind(this);
    // this.panRight = this.panRight.bind(this);
    this.handlePhaseChange = this.handlePhaseChange.bind(this);
  }

  newOscillator(){
    if(!this.state.started) {
      this.oscillator = new Tone.Oscillator({
        type  : "sine" ,
        frequency  : this.state.frequency ,
        detune  : 0 ,
        phase  : this.state.phase,
        partials  : []
        }).toMaster();
        
        this.oscillator.volume.value = this.state.volume;
        this.oscillator.start();
  
      this.setState({
        started: true
      })
    }
  }

  stopOscillator(){
    if(this.state.started) {
      // this.state.osci.stop();
      this.oscillator.stop();
      //or
      // this.state.osci.disconnect();

      // resest volume
      // this.state.osci.volume.value = 0;

      this.setState({
        started: false
      })
    }
  }

  handleFrequencyChange(value) {
    if(this.state.started) {
      console.log(this.oscillator);
      this.oscillator.frequency.value = value;
    }
    this.setState({
      frequency: value
    })    
  }

  volumeUp() {
    if(this.state.started) {
      this.oscillator.volume.value = this.oscillator.volume.value + 1;
    }

    this.setState({
      volume: this.state.volume + 1
    })    
  }

  volumeDown() {
    if(this.state.started) {
      this.oscillator.volume.value = this.oscillator.volume.value - 1;
    }

    this.setState({
      volume: this.state.volume - 1
    }) 
  }

  // panRight() {
  //   let osci = this.state.osci;
  //   console.log(osci);
  //   this.setState({
  //     volume: this.state.volume,
  //     osci: osci
  //   })
  // }

  handlePhaseChange(value) {
    if(this.state.started) {
      this.oscillator.phase = value;
        console.log(this.oscillator.phase);
      this.setState({
        phase: value
      })    
    } 
  }

  render() {
    const Handle = Slider.Handle;

    let handle = (props) => {
      const { value, dragging, index, ...restProps } = props;
      return (
        <Tooltip
          prefixCls="rc-slider-tooltip"
          overlay={value}
          visible={dragging}
          placement="top"
          key={index}
        >
          <Handle value={value} {...restProps} />
        </Tooltip>
      );
    };

    return (
      <div className="tonetool">  
        {!this.state.started && <button className="button button--start" onClick={() => this.newOscillator()}>START</button>}
        {this.state.started &&  <button className="button button--stop" onClick={() => this.stopOscillator()}>STOP</button>}
        <button className="button button--vol" onClick={() => this.volumeDown()}>-</button> 
        <button className="button button--vol" onClick={() => this.volumeUp()}>+</button>
        <div>{Math.round(this.state.volume)}dB</div>
        <div>Frequency: {this.state.frequency} hz</div>
        <Slider min={20} max={20000} defaultValue={440} handle={handle} onChange={this.handleFrequencyChange}/>
        {/* <button onClick={this.panRight}>PAN RIGHT</button> */}
        <div>Phase: </div>
        <Slider min={0} max={360} defaultValue={0} handle={handle} onChange={this.handlePhaseChange}/>
      </div>
    );
  }
}

export default Tonetool;
