import React, { Component } from 'react';
// import logo from '../assets/images/logo.svg';
import Tonetool from '../components/Tonetool';

class App extends Component {

  // constructor(props) {
    
    // super(props);
  
    // this.state = {
    //   tonetools: [<Tonetool />, <Tonetool />]  
    // };
    
    // this.addTonetool = this.addTonetool.bind(this);
  // }

  // addTonetool() {
  //   let tonetool = <Tonetool />;
  //   let tools = this.state.tonetools.push(<Tonetool />)
  //   this.setState({
  //     tonetools: tools
  //   })
  // }

  render() {   
    return (
      <div className="App">
        <header className="App-header">
          {/* <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a> */}
          <h3>Audiometry Tonetool</h3>
        </header>
        <div className="tonetool-wrapper">
          {/* {this.state.tonetools} */}
          <Tonetool />
          <Tonetool />
        {/* <div>
          <button onClick={() => this.addTonetool()}>ADD TONETOOL</button>
        </div> */}
        </div>
      </div>
    );
  }
}

export default App;
